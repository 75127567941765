export default class Message {
  constructor(params) {
    const { from, to, type, payload } = params ?? {};

    this.from = from;
    this.to = to;

    this.type = type;
    this.payload = payload;

    this.timestamp = Date.now();
  }

  static fromJson(json) {
    return new Message(json);
  }
}

import EventEmitter from 'eventemitter3';

import Message from './models/Message';
import { Source, MsgType } from './constants';

export const Type = MsgType;

class MessageManager {
  static Type = MsgType;

  constructor() {
    this._emitter = new EventEmitter();

    this._target = null;
    this._onMessageProxy = null;
  }

  init = (target) => {
    this._target = target;
    this._onMessageProxy = (ev) => this._onMessage(ev, target);
    window.addEventListener('message', this._onMessageProxy, false);
  };

  _onMessage = (ev, target) => {
    if (ev.source !== target) {
      return;
    }

    const msg = Message.fromJson(ev.data);
    console.log('cover _onMessage', msg);

    if (msg.from !== Source.Core) {
      return;
    }
    this._emitter.emit(msg.type, msg.payload);
  };

  /**
   *
   * @param {MsgType} type
   * @param {*} name
   * @param {*} payload
   */
  post = (type, payload = null) => {
    if (this._target === window.self) {
      return;
    }
    this._target.postMessage(
      {
        from: Source.Cover,
        to: Source.Core,
        type,
        payload
      },
      '*'
    );
  };

  /**
   *
   * @param {MsgType} type
   * @param {Function} callback
   */
  on = (type, callback) => {
    this._emitter.on(type, callback);
  };

  /**
   *
   * @param {MsgType} type
   * @param {Function} callback
   */
  off = (type, callback) => {
    this._emitter.off(type, callback);
  };

  release = () => {
    window.removeEventListener('message', this._onMessageProxy);
    this._emitter.removeAllListeners();
  };
}

const mm = new MessageManager();
export default mm;

export const Source = {
  Core: 'core',
  Cover: 'cover'
};

export const MsgType = {
  // actions
  ActInit: 'Action Init',
  ActClose: 'Aciton Close',
  ActChat: 'Action Chat',
  ActMobileChange: 'Action Mobile Change',
  ActTokenChange: 'Action Token Change',
  ActOpenLatest: 'Action Open Latest',
  ActOpenAnnouncement: 'Action Open Announcement',
  ActPlayRingtone: 'Action Play Ringtone',
  ActStopRingtone: 'Action Stop Ringtone',
  ActClickVoice: 'Action Click Voice',
  ActClickCart: 'Action Click Cart',

  // events
  EvtLoad: 'Event Load',
  EvtInit: 'Event Init',
  EvtError: 'Event Error',
  EvtUnreadCountChanged: 'Event Unread Count Changed',
  EvtAnnouncementReceived: 'Event Announcement Received',
  EvtVideoCallIncoming: 'Event Video Call Incoming',
  EvtVisibleChanged: 'Event Visible Changed'
};
